<template>
	<div class="group-item" :class="active ? 'active' : ''" @contextmenu.prevent="showRightMenu($event)">
		<div class="group-avatar">
			<head-image :size="45" :name="group.remark" :url="group.headImage"> </head-image>
		</div>
		<div class="group-name">
			<div>{{group.name}}</div>
		</div>
		<right-menu v-show="rightMenu.show" :pos="rightMenu.pos" :items="menuItems"
        			@close="rightMenu.show = false" @select="onSelectMenu">
        </right-menu>
	</div>
</template>

<script>
	import HeadImage from '../common/HeadImage.vue';
    import RightMenu from '../common/RightMenu.vue';
	export default {
		name: "groupItem",
		components: {
			HeadImage,
			RightMenu
		},
		data() {
			return {
			   rightMenu:{
                   show: false,
                   pos: {
                       x: 0,
                       y: 0
                   }
               }
			}
		},
		props: {
			group: {
				type: Object
			},
			active: {
				type: Boolean
			}
		},
		methods: {
      onSelectMenu(item) {
            	this.$emit(item.key.toLowerCase(),this.group.id);
      },
      showRightMenu(e) {
				this.rightMenu.pos = {
					x: e.x,
					y: e.y
				};
				this.rightMenu.show = "true";
			}
		},
		computed: {
		    menuItems() {
               let items = [];
               if (this.group.ownerId == this.$store.state.userStore.userInfo.id) {
            	 items.push({
          			key: 'CHAT',
                    name: '群发消息',
                    icon: 'el-icon-chat-dot-round'
            	 });
               }
               return items;
            }
		}

	}
</script>

<style lang="scss" >
	.group-item {
		height: 50px;
		display: flex;
		margin-bottom: 1px;
		position: relative;
		padding: 5px;
		padding-left: 10px;
		align-items: center;
		background-color: white;
		white-space: nowrap;
		cursor: pointer;
		
		&:hover {
			background-color: #F8FAFF;
		}
	
		&.active {
			background-color: #F4F9FF;
		}
	
		.group-avatar {
			width: 45px;
			height: 45px;
		}
	
		.group-name {
			padding-left: 10px;
			height: 100%;
			text-align: left;
			line-height: 50px;
			white-space: nowrap;
			overflow: hidden;
			font-size: 15px;
			font-weight: 600;
		}
	}
</style>
